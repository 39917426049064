import Image from "next/image";
import React from "react";

const CandidateLoginContent = ({ companyDetails }) => {
  const myLoader = ({ src }) => src;
  
  return (
    <div className="h-full w-[100%] bg-[url('/images/Login-Page.png')] bg-cover">
      <div className="flex flex-col md:h-full w-full h-[40vh]">
        <div className="absolute top-0 left-0 md:left-0 m-4 md:m-10 md:mx-auto md:text-left text-center w-full">
          {companyDetails ? (
            companyDetails?.slug !== "fedex" ? (
             <div className="relative min-w-[50px] max-w-[100px] min-h-[50px] md:min-w-[75px] max-w-[180px] min-h-[95px] mx-auto md:mx-0">
                <Image
                  id="img_tag_148"
                  loader={myLoader}
                  src={companyDetails?.logo}
                  alt="logo"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            ) : (
              <div className="mx-auto md:mx-0">
                <Image
                  id="img_tag_148"
                  loader={myLoader}
                  src={companyDetails?.logo}
                  width={companyDetails?.slug === "fedex" ? 44 : 60}
                  height={companyDetails?.slug === "fedex" ? 12 : 28}
                  alt="logo"
                />
              </div>
            )
          ) : (
            <div className="mx-auto md:mx-0">
              <Image
                id="img_tag_148"
                src="/images/looplogo.png"
                width={85}
                height={28}
                alt="logo"
              />
            </div>
          )}
        </div>

<div className="flex-1 w-full md:p-3 p-6 flex justify-center items-center mb-[10rem]">
  <span className="md:w-5/6 md:gap-6 gap-y-0 flex flex-col pt-10 pb-8 text-center -mb-6 mt-8">
    <div className="mt-2 md:mt-0">
      <p className="md:text-6xl text-5xl md:font-thin mt-14 font-light text-black font-san">
        WELCOME TO
      </p>
      {companyDetails && (
        <p className="font-bold leading-[74px] md:text-6xl text-6xl md:mt-9 mt-4 text-black font-san">
          {companyDetails.slug.toUpperCase()}
        </p>
      )}
    </div>
  </span>
</div>
      </div>
    </div>
  );
};

export default CandidateLoginContent;
